import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import TextContent from "./TextContent"
import * as styles from "./PageTitle.module.css"
import formatTypography from "../utils/formatTypography"
import GridRowAutoHeight from "./GridRowAutoHeight"

const PageTitle = ({ title, showBreadcrumb = false }) => {
  return (
    <GridRowAutoHeight>
      <div className="flex justify-center">
        <div className="w-10/12 lg:w-22/24 lg:flex lg:items-start lg:items-baseline lg:space-x-1">
          {showBreadcrumb && (
            <TextContent>
              <p
                className={`text-sm leading-snug font-bold ${styles.breadcrumb}`}
              >
                <Link to="/">Projects</Link>
              </p>
            </TextContent>
          )}
          {title.text && (
            <h1 className="font-bold text-xl lg:text-2xl leading-tight lg:leading-tight">
              {formatTypography(title.text)}
            </h1>
          )}
        </div>
      </div>
    </GridRowAutoHeight>
  )
}

PageTitle.propTypes = {
  title: PropTypes.object,
  showBreadcrumb: PropTypes.bool,
}

export default PageTitle

import * as React from "react"
import { Elements } from "prismic-richtext"
import { Link } from "gatsby"
import { linkResolver } from "./linkResolver"

// -- HTML Serializer
const htmlSerializer = function (type, element, content, children, key) {
  switch (type) {
    case Elements.hyperlink: // Hyperlinks
      if (element.data.link_type === "Document") {
        return (
          <Link key={key} to={linkResolver(element.data)}>
            {content}
          </Link>
        )
      }

      return (
        <a
          key={key}
          href={element.data.url}
          target={element.data.target}
          rel={element.data.target ? "noopener" : undefined}
        >
          {children}
        </a>
      )

    default:
      // Always include a default that returns null
      return null
  }
}

export default htmlSerializer
